import style from './Post.module.scss'

import MediaThemeSutro from 'player.style/sutro/react'
import { LinkPreview } from '@dhaiwat10/react-link-preview'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { openViewer } from '../../../../redux/viewerSlice'

import { useSpring, animated } from '@react-spring/web'
import { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'

const Post = ({ post, editMode = false, handleSavePost }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [topHeader, setTopHeader] = useState(false)
  const [editingPost, setEditingPost] = useState(null)
  const [isEditing, setIsEditing] = useState(editMode)

  const _spring_post_header = useSpring({
    from: { opacity: 0, y: -50 },
    to: { opacity: 1, y: 0 },
  })
  const _spring_post_content = useSpring({
    from: { opacity: 0, y: -75 },
    to: { opacity: 1, y: 0 },
  })

  const goToTop = () => {
    const postScroll = document.querySelector('.Posts_container__BEyoX')
    postScroll.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    const headerElement = document.querySelector(`.${style.header}`)

    const observerOptions = {
      threshold: 0, // 當元素完全離開視窗時觸發
      rootMargin: '-120px 0px 0px 0px', // 可以調整這個值來改變觸發的時機
    }

    const observer = new IntersectionObserver((entries) => {
      setTopHeader(!entries[0].isIntersecting)
    }, observerOptions)

    if (headerElement) {
      observer.observe(headerElement)
    }

    // 清理監聽器
    return () => {
      if (headerElement) {
        observer.unobserve(headerElement)
      }
    }
  }, [])

  const handleEdit = () => {
    setIsEditing(true)
    setEditingPost({ ...post })
  }

  const handleContentChange = (index, newText) => {
    if (!isEditing) return
    const newContent = [...editingPost.content]
    newContent[index] = { ...newContent[index], text: newText }
    setEditingPost({ ...editingPost, content: newContent })
  }

  const handleSave = () => {
    const postToSave = {
      ...editingPost,
      id: post.id,
    }
    // console.log('原始 post:', post)
    // console.log('要儲存的 editingPost:', postToSave)
    // console.log('傳遞的 id:', post.id)
    handleSavePost(post.id, postToSave)
    setIsEditing(false)
    setEditingPost(null)
  }

  const handleCancel = () => {
    setIsEditing(false)
    setEditingPost(null)
  }

  if (!post) return null

  return (
    <div className={style.post}>
      {post?.cover && (
        <div className={style.cover}>
          <img src={post.cover} alt={post.title} />
        </div>
      )}
      {topHeader && <PostHeader post={post} goToTop={goToTop} />}
      <div className={style.content}>
        <animated.div className={style.header} style={_spring_post_header}>
          {/* <button className={style.editButton} onClick={handleEdit}>
            <FontAwesomeIcon icon={faPenToSquare} />
          </button>
          {isEditing && (
            <>
              <button className={style.saveButton} onClick={handleSave}>
                Save
              </button>
              <button className={style.cancelButton} onClick={handleCancel}>
                Cancel
              </button>
            </>
          )} */}
          <h1
            className={style.title}
            contentEditable={isEditing}
            suppressContentEditableWarning
            onBlur={(e) =>
              isEditing &&
              setEditingPost({
                ...editingPost,
                title: e.target.innerText,
              })
            }
            onPaste={(e) => {
              e.preventDefault()
              const text = e.clipboardData.getData('text/plain')
              document.execCommand('insertText', false, text)
            }}
          >
            {isEditing ? editingPost?.title : post.title}
          </h1>
          <p
            className={style.subtitle}
            contentEditable={isEditing}
            suppressContentEditableWarning
            onBlur={(e) =>
              isEditing &&
              setEditingPost({
                ...editingPost,
                subtitle: e.target.innerText,
              })
            }
            onPaste={(e) => {
              e.preventDefault()
              const text = e.clipboardData.getData('text/plain')
              document.execCommand('insertText', false, text)
            }}
          >
            {isEditing ? editingPost?.subtitle : post.subtitle}
          </p>
          <div className={style.info}>
            <div className={style.tags}>
              {post.tags.map((tag) => (
                <span key={tag}>#{t(`posts.tags.${tag}`)}</span>
              ))}
            </div>
            <div className={style.date}>
              <p>
                {t('posts.content.publishedAt')}{' '}
                {new Date(post.publishDate).toLocaleDateString()}
              </p>
            </div>
          </div>
          <hr />
        </animated.div>
        <animated.div className={style.content} style={_spring_post_content}>
          {post.content.map((item, index) => {
            if (item.type === 'title') {
              return (
                <h3
                  key={item.text}
                  contentEditable={isEditing}
                  suppressContentEditableWarning
                  onPaste={(e) => {
                    e.preventDefault()
                    const text = e.clipboardData.getData('text/plain')
                    document.execCommand('insertText', false, text)
                  }}
                >
                  {isEditing ? editingPost?.content[index].text : item.text}
                </h3>
              )
            }
            if (item.type === 'text') {
              return item.text
                .split('\n')
                .filter((paragraph) => paragraph.trim())
                .map((paragraph, index) => (
                  <p
                    key={paragraph}
                    contentEditable={isEditing}
                    suppressContentEditableWarning
                    onBlur={(e) =>
                      isEditing &&
                      handleContentChange(index, e.target.innerText)
                    }
                    onPaste={(e) => {
                      e.preventDefault()
                      const text = e.clipboardData.getData('text/plain')
                      document.execCommand('insertText', false, text)
                    }}
                  >
                    {isEditing ? editingPost?.content[index].text : paragraph}
                  </p>
                ))
            }
            if (item.type === 'image') {
              return (
                <div
                  key={item.url}
                  className={style.image}
                  onClick={() =>
                    dispatch(
                      openViewer({
                        src: item.url,
                        fileName: item.name,
                      })
                    )
                  }
                >
                  <img src={item.url} alt={post.title} />
                </div>
              )
            }
            if (item.type === 'video') {
              return (
                <div key={item.url} className={style.video}>
                  <MediaThemeSutro
                    style={{
                      '--media-primary-color': '#c2c2c2',
                      '--media-secondary-color': '#474747',
                      '--media-accent-color': '#ffffff',
                      borderRadius: 'var(--border-radius-m)',
                      overflow: 'hidden',
                    }}
                  >
                    <video
                      slot="media"
                      src={item.url}
                      alt={post.title}
                      playsInline
                      crossOrigin
                    />
                  </MediaThemeSutro>
                </div>
              )
            }
            if (item.type === 'yt_video') {
              // return <YouTube key={item.id} videoId={item.id} />
            }
            if (item.type === 'audio') {
              // return <audio key={item.url} src={item.url} alt={post.title} />
            }
            if (item.type === 'link') {
              return (
                <LinkPreview
                  url={item.url}
                  width="100%"
                  fallback={
                    <a href={item.url} target="_blank" rel="noreferrer">
                      {item.url}
                    </a>
                  }
                />
              )
            }
            return null
          })}
        </animated.div>
      </div>
    </div>
  )
}

const PostHeader = ({ post, goToTop }) => {
  const _spring_post_top_header = useSpring({
    from: { y: -50 },
    to: { y: 0 },
  })
  const _spring_post_top_header_text = useSpring({
    from: { y: 50 },
    to: { y: 0 },
  })
  return (
    <animated.div
      className={style.topHeader}
      style={_spring_post_top_header}
      onClick={goToTop}
    >
      <animated.p style={_spring_post_top_header_text}>{post.title}</animated.p>
    </animated.div>
  )
}

export default Post
