import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import usePosts from '../../hooks/usePosts'
import style from './Posts.module.scss'

import { db } from '../../firebase'
import { collection, addDoc } from 'firebase/firestore'

// UI
import List from './ui/List/List'
import Post from './ui/Post/Post'

function Posts() {
  // const { t } = useTranslation()
  const { postId } = useParams()
  const { posts, currentPost, updatePost, setCurrentPostById } = usePosts()

  useEffect(() => {
    if (postId && posts.length > 0) {
      setCurrentPostById(postId)
    }
  }, [postId, posts])

  const handleSavePost = async (id, updatedPost) => {
    try {
      await updatePost(id, updatedPost)
    } catch (error) {
      console.error('儲存文章時發生錯誤：', error)
      alert('儲存失敗，請稍後再試')
    }
  }

  // Render
  const renderContent = () => {
    if (postId && currentPost) {
      return (
        <Post
          post={currentPost}
          editMode={false}
          handleSavePost={handleSavePost}
        />
      )
    }

    return (
      <div className={style.posts}>
        <List posts={posts} />
      </div>
    )
  }

  return <div className={style.container}>{renderContent()}</div>
}

export default Posts
