import { useNavigate } from 'react-router-dom'
import style from './List.module.scss'

import { useSprings, animated } from '@react-spring/web'

const List = ({ posts }) => {
  const navigate = useNavigate()

  const _spring_post_list = useSprings(
    posts.length,
    posts.map((_, index) => ({
      from: { opacity: 0, transform: 'translateY(20%)' },
      to: { opacity: 1, transform: 'translateY(0)' },
      delay: 70 * index,
    }))
  )

  return (
    <div className={style.list}>
      {posts.map((item, index) => (
        <animated.div
          className={style.item}
          key={item.id}
          onClick={() => navigate(`/posts/${item.id}`)}
          style={_spring_post_list[index]}
        >
          {item.cover && (
            <div className={style.cover}>
              <img src={item.cover} alt={item.title} />
            </div>
          )}
          <div className={style.content}>
            <h3 className={style.title}>{item.title}</h3>
            <p className={style.subtitle}>{item.subtitle}</p>
          </div>
        </animated.div>
      ))}
    </div>
  )
}

export default List
